<template>
    <v-row class="moloni-purchase-orders-preview">
        <v-col cols="12" md="9">
            <v-card v-if="purchaseOrderData">
                <!-- Header -->
                <v-card-text class="py-9 px-8">
                    <div class="purchaseOrder-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
                        <!-- Left Content -->
                        <div class="mb-8 mb-sm-0">
                            <div class="d-flex align-center mb-6">
                                <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>
                                <span class="text--primary font-weight-bold text-xl">
                                    {{ appName }}
                                </span>
                            </div>
                        </div>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <!-- Payment Details -->
                <v-card-text class="py-9 px-8">
                    <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
                        <div class="mb-8 mb-sm-0">
                            <p class="font-weight-medium text-xl text--primary mb-4">{{ getTrad(text).purchaseOrder }} {{ purchaseOrderData.document_set_name }}/{{ purchaseOrderData.number }}</p>
                            <p class="mb-2">
                                <span>{{ getTrad(text).issuedDate }}: </span>
                                <span class="font-weight-semibold">{{ $moment(purchaseOrderData.date).format('DD-MM-YYYY') }}</span>
                            </p>
                            <p class="mb-2">
                                <span>{{ getTrad(text).dueDate }}: </span>
                                <span class="font-weight-semibold">{{ $moment(purchaseOrderData.expiration_date).format('DD-MM-YYYY') }}</span>
                            </p>
                        </div>
                        <div>
                            <p class="font-weight-semibold payment-details-header">{{ getTrad(text).to }}:</p>
                            <p class="mb-1">
                                {{ purchaseOrderData.entity_name }}
                            </p>
                            <p class="mb-1">{{ purchaseOrderData.entity_address }}</p>
                            <p class="mb-1">{{ purchaseOrderData.entity_zip_code }}, {{ purchaseOrderData.entity_city }}</p>
                            <p class="mb-2">
                                {{ purchaseOrderData.entity_country }}
                            </p>

                            <p class="mb-0">
                                <strong>{{ getTrad(text).vat }}:</strong>{{ purchaseOrderData.entity_vat }}
                            </p>
                        </div>
                    </div>
                </v-card-text>

                <!-- Products -->
                <v-simple-table class="purchased-items-table">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>{{ getTrad(text).productsColumns.reference }}</th>
                                <th>{{ getTrad(text).productsColumns.ean }}</th>
                                <th>{{ getTrad(text).productsColumns.name }}</th>
                                <th>{{ getTrad(text).productsColumns.quantity }}</th>
                                <th>{{ getTrad(text).productsColumns.unit }}</th>
                                <th>{{ getTrad(text).productsColumns.unitPrice }}</th>
                                <th>{{ getTrad(text).productsColumns.tax }}</th>
                                <th>{{ getTrad(text).productsColumns.total }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in purchaseOrderData.products" :key="item.reference">
                                <td class="text-no-wrap">
                                    {{ item.reference }}
                                </td>
                                <td class="text-no-wrap">
                                    {{ item.ean ? item.ean : '---' }}
                                </td>
                                <td class="text-no-wrap">
                                    {{ item.name }}
                                </td>
                                <td class="text-no-wrap">
                                    {{ item.qty }}
                                </td>
                                <td class="text-no-wrap">
                                    {{ item.unit_short_name }}
                                </td>
                                <td class="text-center">{{ item.price }}€</td>
                                <td class="text-center">{{ item.taxes[0] ? `${item.taxes[0].value}%` : 'free' }}</td>
                                <td class="text-center">{{ item.taxes[0] ? (item.price + item.taxes[0].total_value).toFixed(2) : item.price }}€</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <!-- MB References -->
                <v-card-text v-if="purchaseOrderData.mb_references.length != 0" class="py-9 px-8">
                    <p class="font-weight-medium text-xl text--primary mb-4">MB References</p>
                    <v-simple-table class="purchased-items-table mt-4">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>{{ getTrad(text).mbColumns.entity }}</th>
                                    <th>{{ getTrad(text).mbColumns.reference }}</th>
                                    <th>{{ getTrad(text).mbColumns.value }}</th>
                                    <th>{{ getTrad(text).mbColumns.date }}</th>
                                    <th>{{ getTrad(text).mbColumns.paymentStatus }}</th>
                                    <th>{{ getTrad(text).mbColumns.paymentDate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in purchaseOrderData.mb_references" :key="item.reference_id">
                                    <td class="text-no-wrap">
                                        {{ item.entity }}
                                    </td>
                                    <td class="text-no-wrap">
                                        {{ item.reference_id }}
                                    </td>
                                    <td class="text-no-wrap">{{ item.value }}€</td>
                                    <td class="text-no-wrap">
                                        {{ $moment(item.creation_date).format('DD-MM-YYYY hh:mm') }}
                                    </td>
                                    <td class="text-no-wrap">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-avatar
                                                    size="30"
                                                    :color="item.paid == 1 ? 'success' : 'error'"
                                                    :class="`v-avatar-light-bg ${item.paid == 1 ? 'success' : 'error'}--text`"
                                                    v-bind="attrs"
                                                    v-on="on">
                                                    <v-icon size="18" :color="item.paid == 1 ? 'success' : 'error'">
                                                        {{ item.paid == 1 ? icons.mdiCheckCircleOutline : icons.mdiAlertCircleOutline }}
                                                    </v-icon>
                                                </v-avatar>
                                            </template>
                                            <span>{{ item.paid == 1 ? 'Paid' : 'Unpaid' }}</span>
                                        </v-tooltip>
                                    </td>
                                    <td class="text-center">{{ item.payment_date ? $moment(item.payment_date).format('DD-MM-YYYY hh:mm') : '' }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table></v-card-text
                >

                <!-- Total -->
                <v-card-text class="py-9 px-8">
                    <div class="purchaseOrder-total d-flex justify-space-between flex-column flex-sm-row">
                        <div class="mb-2 mb-sm-0">
                            <v-simple-table class="purchased-items-table">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>{{ getTrad(text).taxesColumns.designation }}</th>
                                            <th>{{ getTrad(text).taxesColumns.value }}</th>
                                            <th>{{ getTrad(text).taxesColumns.incidence }}</th>
                                            <th>{{ getTrad(text).taxesColumns.total }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="key in Object.keys(taxes)" :key="key">
                                            <td class="text-no-wrap">
                                                {{ taxes[key].name }}
                                            </td>
                                            <td class="text-center">{{ taxes[key].value }}%</td>
                                            <td class="text-center">
                                                {{ taxes[key].incidence }}
                                            </td>
                                            <td class="text-center">
                                                {{ taxes[key].total.toFixed(2) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                        <div>
                            <table>
                                <tr>
                                    <td class="pe-16">{{ getTrad(text).totalColumns.subTotal }}:</td>
                                    <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">{{ purchaseOrderData.gross_value }}€</th>
                                </tr>
                                <tr>
                                    <td class="pe-16">{{ getTrad(text).totalColumns.discount }}:</td>
                                    <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">{{ purchaseOrderData.comercial_discount_value + purchaseOrderData.financial_discount_value }}€</th>
                                </tr>
                                <tr v-for="key in Object.keys(taxes)" :key="`total_${key}`">
                                    <td class="pe-16">{{ taxes[key].name }}</td>
                                    <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">{{ taxes[key].total.toFixed(2) }}€</th>
                                </tr>
                            </table>
                            <v-divider class="mt-4 mb-3"></v-divider>
                            <table class="w-full">
                                <tr>
                                    <td class="pe-16">{{ getTrad(text).totalColumns.total }}:</td>
                                    <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">{{ purchaseOrderData.net_value }}€</th>
                                </tr>
                            </table>
                        </div>
                    </div>
                </v-card-text>

                <v-divider></v-divider>
                <!-- Transport -->
                <v-card-text v-if="purchaseOrderData.delivery_method_id" class="py-9 px-8">
                    <p class="font-weight-medium text-xl text--primary mb-4">Transport</p>
                    <v-simple-table class="transport-items-table overflow-hidden mt-4">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>{{ getTrad(text).transportColumns.expedition }}</th>
                                    <th>{{ getTrad(text).transportColumns.vehicle }}</th>
                                    <th>{{ getTrad(text).transportColumns.transactionDate }}</th>
                                    <th>{{ getTrad(text).transportColumns.load }}</th>
                                    <th>{{ getTrad(text).transportColumns.unload }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-no-wrap">
                                        {{ purchaseOrderData.delivery_method_name }}
                                    </td>
                                    <td class="text-no-wrap">
                                        {{ purchaseOrderData.vehicle_id ? `${purchaseOrderData.vehicle_name}(${purchaseOrderData.vehicle_number_plate})` : '' }}
                                    </td>
                                    <td class="text-no-wrap">{{ purchaseOrderData.delivery_datetime ? $moment(purchaseOrderData.delivery_datetime).format('DD-MM-YYYY hh:mm') : '' }}</td>
                                    <td class="text-no-wrap">
                                        {{ purchaseOrderData.delivery_departure_address ? purchaseOrderData.delivery_departure_address + ',' : '' }} <br />
                                        {{ purchaseOrderData.delivery_departure_zip_code }} {{ purchaseOrderData.delivery_departure_city }}
                                    </td>
                                    <td class="text-no-wrap">
                                        {{ purchaseOrderData.delivery_destination_address ? purchaseOrderData.delivery_destination_address + ',' : '' }} <br />
                                        {{ purchaseOrderData.delivery_destination_zip_code }} {{ purchaseOrderData.delivery_destination_city }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table></v-card-text
                >
            </v-card>
        </v-col>

        <!-- Right Column: PurchaseOrder Action -->
        <v-col cols="12" md="3">
            <v-card>
                <v-card-text>
                    <!-- <v-btn color="primary" class="mb-3" block @click="isPurchaseOrderSendSidebarActive = true">
                        <v-icon class="me-2" left>
                            {{ icons.mdiSendOutline }}
                        </v-icon>
                        <span>Send PurchaseOrder</span>
                    </v-btn> -->
                    <v-btn :disabled="purchaseOrderData.status == 0" class="mb-3" color="primary" block @click="downloadPurchaseOrder"
                        ><v-icon class="me-2" left>
                            {{ icons.mdiDownload }}
                        </v-icon>
                        <span>Download</span>
                    </v-btn>
                    <v-btn :disabled="purchaseOrderData.status == 0" class="mb-3" color="primary" block @click="printPurchaseOrder"
                        ><v-icon class="me-2" left>
                            {{ icons.mdiPrinter }}
                        </v-icon>
                        <span>Print</span>
                    </v-btn>
                    <!-- <v-btn class="mb-3" color="secondary" block outlined :to="{ name: 'apps-purchaseOrder-edit', params: { id: $route.params.id } }"> Edit PurchaseOrder </v-btn>
                    <v-btn color="success" block @click="isAddPaymentSidebarActive = true">
                        <v-icon class="me-2" left>
                            {{ icons.mdiCurrencyUsd }}
                        </v-icon>
                        <span>Add Payment</span>
                    </v-btn> -->
                </v-card-text>
            </v-card>
        </v-col>

        <!-- purchaseOrder send drawer -->
        <!-- <purchaseOrder-sidebar-send-purchaseOrder v-model="isPurchaseOrderSendSidebarActive"></purchaseOrder-sidebar-send-purchaseOrder> -->

        <!-- purchaseOrder add payment drawer -->
        <!-- <purchaseOrder-sidebar-add-payment v-model="isAddPaymentSidebarActive"></purchaseOrder-sidebar-add-payment> -->
    </v-row>
</template>

<script>
    import { ref, onUnmounted } from '@vue/composition-api';
    import store from '@/store';
    import router from '@/router';
    import { mdiSendOutline, mdiDownload, mdiPrinter, mdiAlertCircleOutline, mdiCheckCircleOutline } from '@mdi/js';

    import themeConfig from '@themeConfig';
    import purchaseOrderStoreModule from '../purchaseOrderStoreModule';
    import getTrad from '../../../i18n/getTrad';

    export default {
        components: {
            /* PurchaseOrderSidebarSendPurchaseOrder,
            PurchaseOrderSidebarAddPayment, */
        },
        mixins: [getTrad],
        setup() {
            const purchaseOrderData = ref(null);

            const isPurchaseOrderSendSidebarActive = ref(false);
            const isAddPaymentSidebarActive = ref(false);
            const taxes = {};
            // PurchaseOrder Description
            // ? Your real data will contain this information

            // ————————————————————————————————————
            //* ——— Store Registration
            // ————————————————————————————————————

            const INVOICE_APP_STORE_MODULE_NAME = 'moloni-purchase-orders';

            // Register module
            if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
                store.registerModule(INVOICE_APP_STORE_MODULE_NAME, purchaseOrderStoreModule);
            }

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
            });

            // ————————————————————————————————————
            //* ——— PurchaseOrder
            // ————————————————————————————————————

            store
                .dispatch('moloni-purchase-orders/fetchPurchaseOrder', { id: router.currentRoute.params.id })
                .then((response) => {
                    purchaseOrderData.value = response.data.data;
                    for (let product of response.data.data.products) {
                        if (product.taxes.length > 0 && taxes[product.taxes[0].tax_id]) {
                            taxes[product.taxes[0].tax_id] = {
                                name: product.taxes[0].name,
                                value: product.taxes[0].value,
                                incidence: taxes[product.taxes[0].tax_id].incidence + product.price * product.qty,
                                total: taxes[product.taxes[0].tax_id].total + product.taxes[0].total_value,
                            };
                        } else if (product.taxes.length > 0) {
                            taxes[product.taxes[0].tax_id] = {
                                name: product.taxes[0].name,
                                value: product.taxes[0].value,
                                incidence: product.price * product.qty,
                                total: product.taxes[0].total_value,
                            };
                        } else {
                            taxes['00000'] = {
                                name: 'Free',
                                value: 0,
                                incidence: product.price * product.qty,
                                total: 0,
                            };
                        }
                    }
                })
                .catch((error) => {
                    purchaseOrderData.value = undefined;
                });

            const printPurchaseOrder = () => {
                window.print();
            };

            const downloadPurchaseOrder = () => {
                store
                    .dispatch('moloni-purchase-orders/fetchPurchaseOrderDownloadLink', { id: router.currentRoute.params.id })
                    .then((response) => {
                        console.log(response.data);
                        document.location.href = response.data.data.url;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            };

            const text = 'views.purchaseOrder.preview';

            return {
                purchaseOrderData,
                taxes,
                // purchaseOrderDescription,
                printPurchaseOrder,
                downloadPurchaseOrder,
                // drawer
                isPurchaseOrderSendSidebarActive,
                isAddPaymentSidebarActive,

                // themeConfig
                appName: themeConfig.app.name,
                appLogo: themeConfig.app.logo,

                icons: {
                    mdiSendOutline,
                    mdiPrinter,
                    mdiDownload,
                    mdiAlertCircleOutline,
                    mdiCheckCircleOutline,
                },
                text,
            };
        },
    };
</script>

<style lang="scss">
    @import '~@core/preset/preset/apps/moloni.scss';

    @media print {
        .v-application {
            background: none !important;
        }

        .app-navigation-menu,
        .v-app-bar,
        .v-footer,
        .product-buy-now,
        .moloni-purchase-orders-preview.row .col-12:last-of-type {
            display: none !important;
        }

        .moloni-purchase-orders-preview.row .col-12:first-child {
            max-width: 100% !important;
            flex: 1 !important;
        }

        .v-main,
        .app-content-container {
            padding: 0px !important;
        }

        .v-card {
            box-shadow: none !important;
        }

        .moloni-purchase-orders-preview {
            .purchaseOrder-header,
            .payment-details,
            .purchaseOrder-total {
                &.d-flex {
                    flex-direction: row !important;
                }
            }
        }
    }
</style>
